import React from 'react';
import { FaLinkedin, FaGithub, FaEnvelope } from 'react-icons/fa';

interface CirclePicProp {
    src: string;
    alt: string;
}

const CirclePic: React.FC<CirclePicProp> = ({ src, alt }) => {
    return (
        <div className="flex flex-col items-center space-y-2 bg-terminal-background p-4 border-terminal-subtle rounded-lg">
            <img
                src={src}
                alt={alt}
            />
            <div className="flex justify-center space-x-3">
                <a href="https://linkedin.com/in/actiii03/" className="text-terminal-accent hover:text-terminal-text">
                    <FaLinkedin size="24" />
                </a>
                <a href="https://github.com/ActIII03" className="text-terminal-accent hover:text-terminal-text">
                    <FaGithub size="24" />
                </a>
                <a href="mailto:a.touche03@google.com" className="text-terminal-accent hover:text-terminal-text">
                    <FaEnvelope size="24" />
                </a>
            </div>
        </div>
    );
}

export default CirclePic;

