import React from 'react';
import CirclePic from './CirclePic';
import WelcomeMessage from './WelcomeMessage';
import avatar from 'assets/img/new-cover-page.png';

const AboutMe: React.FC = () => {
  return (
    <div className="flex flex-col items-center md:flex-row md:items-center md:gap-12 lg:gap-20 sm:gap-15">
        <CirclePic src={avatar} alt="Avatar image" />
        <WelcomeMessage />
        </div>
      );
    };

export default AboutMe;
